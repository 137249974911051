@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Noto Sans", sans-serif;
}

@layer components {
  .nav-link {
    @apply text-base font-semibold leading-6 text-white hover:text-[#C1963D];
  }

  .nav-link.active {
    @apply text-[#C1963D];
  }
}

.logo-text {
  color:#C1963D;
  text-shadow: 0.0625rem 0.0625rem 0 #fff;
}

.magic-button {
  --border-bottom-width: 1px;
  --border-color:#967430; /* Updated color */
  --border-left-width: 1px;
  --border-right-width: 1px;
  --border-style: solid;
  --border-top-width: 1px;
  background: 
  #C1963D;
  height: 100%;
  opacity: 1;
  box-shadow: 0 6px 34px -6px#685021,
    /* Updated shadow */ inset 0 5px 10px -8px #9e5def,
    /* Updated shadow */ inset 0 -5px 10px -8px #9e5def,
    /* Updated shadow */ inset -5px 0 10px -8px #9e5def,
    /* Updated shadow */ inset 5px 0 10px -8px #9e5def; /* Updated shadow */
}

.magic-button:hover {
  --border-bottom-width: 1px;
  --border-color: #000000; /* Updated color */
  --border-left-width: 1px;
  --border-right-width: 1px;
  --border-style: solid;
  --border-top-width: 1px;
  background: linear-gradient(
    90deg,
   #000000,
    #000000 78.9465%
  ); /* Updated gradient */
  height: 100%;
  opacity: 1;
  box-shadow: 0 6px 34px -3px#fda900,
    /* Updated shadow */ inset 0 5px 20px -7px #000000,
    /* Updated shadow */ inset 0 -5px 20px -7px #000000,
    /* Updated shadow */ inset -5px 0 20px -7px #000000,
    /* Updated shadow */ inset 5px 0 20px -7px #000000; /* Updated shadow */
}

.bg-gradient-1 {
  background: linear-gradient(
    180deg,
    #232323 10%,
    #232323.47%,
    #232323 33.49%,
    #232323 63.16%,
    #232323 85.69%,
   #232323 100%
  );
}
@keyframes upDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(40px);
  }
}

@keyframes leftRight {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(50px);
  }
}

.up-down {
  @apply animate-[upDown_2s_ease-in-out_infinite];
}

.left-right {
  @apply animate-[leftRight_5s_ease-in-out_infinite];
}
.marquee-left {
  animation: marquee 20s infinite;
}

.marquee-right {
  animation: marquee 20s infinite;
  animation-direction: reverse;
  animation-delay: -3s;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}